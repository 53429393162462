import { Injectable } from '@angular/core';

// RxJs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// App
import { CreateBotRequest } from 'app/domain/dtos/configuration/CreateBotRequest';
import { PublishBotRequest } from 'app/domain/dtos/configuration/PublishBotRequest';
import { BotInfoRequest } from 'app/domain/dtos/configuration/BotInfoRequest';
import { BotInfoResponse } from 'app/domain/dtos/configuration/BotInfoResponse';
import { BotResponse } from 'app/domain/dtos/configuration/BotResponse';

// Root Singleton Services
import { ApiService, HttpClientService } from 'app/core/services';

@Injectable()
export class BotService {
  apiUrl: string;

  constructor(private http: HttpClientService, private apiService: ApiService) {
    this.apiUrl = `${this.apiService.apiUrl}bots`;
  }

  postBot(request: CreateBotRequest): Observable<number> {
    const body = JSON.stringify(request);
    return this.http.post(`${this.apiUrl}`, body, true).pipe(
      map((botId: number) => {
        return botId;
      })
    );
  }

  publishBot(request: PublishBotRequest) {
    const body = JSON.stringify(request);
    return this.http.post(`${this.apiUrl}/publish`, body, true);
  }

  getBotInfo(botId: number): Observable<BotInfoResponse> {
    return this.http.get(`${this.apiUrl}/info?botId=${botId}`, true).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getBots(): Observable<BotResponse[]> {
    return this.http.get(`${this.apiUrl}`, true).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  putBotInfo(request: BotInfoRequest) {
    const body = JSON.stringify(request);
    return this.http.put(`${this.apiUrl}/info`, body, true);
  }

  getTimezones(botId: number): Observable<string[]> {
    return this.http.get(`${this.apiUrl}/timezones?botId=${botId}`, true).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
